export const category = [
  // {
  //   id: 1,
  //   category_name: "Dreshes",
  //   subCat: [
  //     {
  //       id: 11,
  //       category_name: "Men's dresses",
  //     },
  //     {
  //       id: 12,
  //       category_name: "Women's dresses",
  //     },
  //     {
  //       id: 13,
  //       category_name: "Baby's dresses",
  //     },
  //   ],
  //   quantity: "100 Products",
  //   img: "../assets/img/cat-3.jpg",
  // },
  {
    id: 2,
    category_name: "Shirts",
    quantity: "100 Products",
    img: "../assets/img/shirts.jpg",
  },
  {
    id: 3,
    category_name: "Jeans",
    quantity: "100 Products",
    img: "../assets/img/jeans.jpg",
  },
  {
    id: 4,
    category_name: "Swimwear",
    quantity: "100 Products",
    img: "../assets/img/swimwear.jpg",
  },
  {
    id: 5,
    category_name: "Sportwear",
    quantity: "100 Products",
    img: "../assets/img/sportswear.jpg",
  },
  {
    id: 6,
    category_name: "Jumpsuits",
    quantity: "100 Products",
    img: "../assets/img/jumpwear.jpg",
  },
  {
    id: 7,
    category_name: "Blazer",
    quantity: "100 Products",
    img: "../assets/img/blazer.jpg",
  },
  {
    id: 8,
    category_name: "Jackets",
    quantity: "100 Products",
    img: "../assets/img/jacket2.jpg",
  },
  {
    id: 9,
    category_name: "Shoes",
    quantity: "100 Products",
    img: "../assets/img/cat-3.jpg",
  },
  
  {
    id: 11,
    category_name: "Shoes",
    quantity: "100 Products",
    img: "../assets/img/cat-3.jpg",
  },
  {
    id: 12,
    category_name: "Sleepwear",
    quantity: "100 Products",
    img: "../assets/img/sleepwear.jpg",
  },
];

export const nav = [
  {
    path: "/",
    text: "Home",
  },
  {
    path: "/shop",
    text: "Shop",
  },
  {
    path: "/about",
    text: "About",
  },
  {
    path: "/contact",
    text: "Contact",
  },
  {
    path: "/help",
    text: "Help",
  },
  {
    path: "/faqs",
    text: "FAQs",
  },
];

export const topdropdown = [
  {
    id: 1,
    btn: "My Account",
  },

  {
    id: 3,
    btn: "USD",
  },
  {
    id: 2,
    btn: "EN",
  },
];
export const socialIcon = [
  {
    icon: <i className="fab fa-facebook-f"></i>,
    name: "facebook",
  },
  {
    icon: <i className="fab fa-twitter"></i>,
    name: "twitter",
  },
  {
    icon: <i className="fab fa-youtube"></i>,
    name: "youtube",
  },
  {
    icon: <i className="fab fa-instagram"></i>,
    name: "instagram",
  },
];

export const products = [
  {
    id: 1,
    product_name: "Sleepwear",
    product_img: "../assets/img/sleepwear.jpg",
    price: "34.99",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 12,
  },

  {
    id: 2,
    product_name: "T-Shirt",
    product_img: "../assets/img/product-2.jpg",
    price: "12.99",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 91,
  },

  {
    id: 3,
    product_name: "Blazer",
    product_img: "../assets/img/blazer.jpg",
    price: "18.99",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 54,
  },

  {
    id: 4,
    product_name: "Shoes",
    product_img: "../assets/img/product-4.jpg",
    price: "13.99",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 72,
  },
  {
    id: 5,
    product_name: "Lamp",
    product_img: "../assets/img/product-3.jpg",
    price: "23.99",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 108,
  },

  {
    id: 6,
    product_name: "Smart Watch",
    product_img: "../assets/img/product-6.jpg",
    price: "43.99",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 19,
  },
  {
    id: 7,
    product_name: "Ladies Kurti",
    product_img: "../assets/img/product-7.jpg",
    price: "63.99",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 65,
  },
  {
    id: 8,
    product_name: "Jackets",
    product_img: "../assets/img/jacket2.jpg",
    price: "53.99",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 33,
  },
  {
    id: 9,
    product_name: "Jumpwear",
    product_img: "../assets/img/jumpwear.jpg",
    price: "53.99",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 70,
  },
  {
    id: 10,
    product_name: "Sportswear",
    product_img: "../assets/img/sportswear.jpg",
    price: "53.99",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 46,
  },
  {
    id: 11,
    product_name: "Swimwear",
    product_img: "../assets/img/swimwear.jpg",
    price: "53.99",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 50,
  },
  {
    id: 12,
    product_name: "Jeans",
    product_img: "../assets/img/jeans.jpg",
    price: "53.99",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 88,
  },
  {
    id: 13,
    product_name: "Blazer",
    product_img: "../assets/img/blazer2.jpg",
    price: "18.99",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 39,
  },
  {
    id: 16,
    product_name: "Jeans",
    product_img: "../assets/img/jeans2.jpg",
    price: "53.99",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 73,
  },
  {
    id: 14,
    product_name: "Blazer",
    product_img: "../assets/img/blazer3.jpg",
    price: "18.99",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 108,
  },
  {
    id: 19,
    product_name: "T-Shirt",
    product_img: "../assets/img/shirt2.jpg",
    price: "12.99",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 83,
  },
  {
    id: 15,
    product_name: "Blazer",
    product_img: "../assets/img/blazer4.jpg",
    price: "18.99",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 87,
  },
  
  {
    id: 17,
    product_name: "Jeans",
    product_img: "../assets/img/jeans3.jpg",
    price: "53.99",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 44,
  },
  {
    id: 18,
    product_name: "Jeans",
    product_img: "../assets/img/jeans4.jpg",
    price: "53.99",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 23,
  },
 
  {
    id: 20,
    product_name: "T-Shirt",
    product_img: "../assets/img/shirt3.jpg",
    price: "12.99",
    stars: Array(5).fill(
      <small className="fa fa-star text-primary mr-1"></small>
    ),
    ratingCount: 99,
  },
];
export const banner = [
  {
    title: "Special Offer",
    discount: "save 20%",
    img: "../assets/img/offer-1.jpg",
    btn: "Shop Now",
  },
  {
    title: "Special Offer",
    discount: "save 20%",
    img: "../assets/img/offer-2.jpg",
    btn: "Shop Now",
  },
];
export const features = [
  {
    id: 1,
    icon: <h1 className="fa fa-check text-primary m-0 mr-3"></h1>,
    title: "Quality Product",
  },
  {
    id: 2,
    icon: <h1 className="fa fa-shipping-fast text-primary m-0 mr-2"></h1>,
    title: "Free Shipping",
  },
  {
    id: 3,
    icon: <h1 className="fas fa-exchange-alt text-primary m-0 mr-3"></h1>,
    title: "14-Day Return",
  },
  {
    id: 4,
    icon: <h1 className="fa fa-phone-volume text-primary m-0 mr-3"></h1>,
    title: "24/7 Support",
  },
];

export const vendors = [
  {
    image: "../assets/img/vendor-1.jpg",
  },
  {
    image: "../assets/img/vendor-2.jpg",
  },
  {
    image: "../assets/img/vendor-3.jpg",
  },
  {
    image: "../assets/img/vendor-4.jpg",
  },
  {
    image: "../assets/img/vendor-5.jpg",
  },
  {
    image: "../assets/img/vendor-6.jpg",
  },
  {
    image: "../assets/img/vendor-7.jpg",
  },
  {
    image: "../assets/img/vendor-8.jpg",
  },
];

export const carousel = [
  {
    cover_img: "../assets/img/carousel-1.jpg",
    title: "Men Fashion",
    description:
      "Lorem rebum magna amet lorem magna erat diam stet. Sadips duo stet amet amet ndiam elitr ipsum diam",
    btn: "Shop Now",
  },
  {
    cover_img: "../assets/img/carousel-2.jpg",
    title: "Women Fashion",
    description:
      "Lorem rebum magna amet lorem magna erat diam stet. Sadips duo stet amet amet ndiam elitr ipsum diam",
    btn: "Shop Now",
  },
  {
    cover_img: "../assets/img/carousel-3.jpg",
    title: "Kids Fashion",
    description:
      "Lorem rebum magna amet lorem magna erat diam stet. Sadips duo stet amet amet ndiam elitr ipsum diam",
    btn: "Shop Now",
  },
];

export const footerTouch = [
  {
    icon: <i className="fa fa-map-marker-alt text-primary mr-3"></i>,
    contact: "624 N WALNUT ST GARDNER , KS 66030",
  },
  {
    icon: <i className="fa fa-envelope text-primary mr-3"></i>,
    contact: "info@nexgengood.com",
  },
  {
    icon: <i className="fa fa-phone-alt text-primary mr-3"></i>,
    contact: "(480) 787-0039",
  },
];

export const footer = [
  {
    id: 1,
    header: "Quick Shop",
  },
  {
    id: 2,
    header: "My Account",
  },
];

export const sidebar = [
  {
    id: 1,
    header: "Filter By Price",
    all: "All Price",
    total_quantity: "1000",
    subItem: [
      {
        variety: "0-100",
        quantity: "150",
      },
      {
        variety: "100-200",
        quantity: "150",
      },
      {
        variety: "200-300",
        quantity: "150",
      },
      {
        variety: "300-400",
        quantity: "150",
      },
      {
        variety: "400-500",
        quantity: "150",
      },
    ],
  },
  {
    id: 2,
    header: "Filter By Color",
    all: "All Color",
    total_quantity: "1000",
    subItem: [
      {
        variety: "Black",
        quantity: "30",
      },
      {
        variety: "White",
        quantity: "11",
      },
      {
        variety: "Green",
        quantity: "20",
      },
      {
        variety: "Red",
        quantity: "10",
      },
      {
        variety: "Yellow",
        quantity: "15",
      },
    ],
  },
  {
    id: 3,
    header: "Filter By Size",
    all: "All Size",
    total_quantity: "1000",
    subItem: [
      {
        variety: "XS",
        quantity: "60",
      },
      {
        variety: "S",
        quantity: "19",
      },
      {
        variety: "L",
        quantity: "150",
      },
      {
        variety: "XL",
        quantity: "16",
      },
      {
        variety: "XXL",
        quantity: "15",
      },
    ],
  },
];
